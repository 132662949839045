const pageExtractor = (
  slug: string,
  data: Record<string, unknown>,
  language: string,
  siteUrl: string,
  siteName: string
) => {
  const url = `${siteUrl}${slug}`;

  return {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": url,
    publisher: {
      "@type": "Organization",
      name: siteName,
      logo: {
        "@type": "ImageObject",
        url: `${siteUrl}/unilever-logo.png`
      }
    },
    reviewedBy: {
      "@type": "Organization",
      name: siteName,
      logo: {
        "@type": "ImageObject",
        url: `${siteUrl}/unilever-logo.png`
      }
    }
  };
};

export default pageExtractor;
