import React, { FunctionComponent, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { useSiteMetadata } from "../../../../common/src/hooks/useSiteMetadata";

interface SeoProps {
  description?: string;
  title?: string;
  imageUrl?: string;
  imageAlt?: string;
  thumbnail?: string;
}

const SEO: FunctionComponent<SeoProps> = ({ title, description, imageAlt, thumbnail }) => {
  const { language, sanitySiteSettings } = useContext(LocalizedContext);
  const metaDescription = description || sanitySiteSettings?.brandName || "TRESemmé";
  const siteTitle = title + " | " + sanitySiteSettings?.name || "TRESemmé";
  const twitterImageAlt = imageAlt || metaDescription;
  const { siteName } = useSiteMetadata(language);
  const url = typeof window !== "undefined" ? window.location.href : "";
  const pageImage = thumbnail || "https://cdn.sanity.io/images/4nopozul/tresemme-staging-us/424e61a64f6e33a34797b97b71d3a1bde22e4604-3963x1966.jpg";

  return (
    <Helmet>
      <title>{siteTitle}</title>
      <meta name="description" content={metaDescription} />

      {/* Open Graph tags */}
      <meta property="og:title" content={siteTitle} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={pageImage} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={siteTitle} />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:image" content={pageImage} />
      <meta name="twitter:image:alt" content={twitterImageAlt} />
      <meta name="twitter:description" content={metaDescription} />
    </Helmet>
  );
};

export default SEO;
