import React, { FunctionComponent } from "react";
import { Card } from "react-bootstrap";
import { Link } from "../Link";
import { VideoBannerInterface } from "../../types/SanityTypes";
import "./styles.scss";

const VideoBanner: FunctionComponent<VideoBannerInterface> = ({
  webm,
  fallback,
  height,
  alt,
  title,
  description,
  ctaLabel,
  externalLink,
  internalLink,
  isThisHomePage,
  bgColor,
  contentAlignment,
  isHero,
  isCarouselItem,
  autoPlay,
  controls
}) => {
  if (!webm || !fallback) {
    return null;
  }
  const themeColor = bgColor?.title?.toLowerCase();
  return (
    <Card className={`video-banner rounded-0 border-0 ${isHero && "video-banner_overlay"} ${isCarouselItem && "video-banner_carousel"}`}>
      <video preload="auto" title={alt} autoPlay={autoPlay} height={height} controls={controls} loop muted playsInline>
        <track kind="captions" />
        {fallback && fallback.asset?.url && <source src={fallback.asset?.url} type="video/quicktime" />}
        {webm && webm.asset?.url && <source src={webm.asset?.url} type="video/webm" />}
      </video>
      <Card.ImgOverlay>
        <section
          className={`video-banner__content 
          ${ isThisHomePage ? "video-banner__content__golden" : ""} 
          video-banner__content--${themeColor || "transparent"} 
          video-banner--position-${contentAlignment}`}
        >
          {title && (isHero ? <h1>{title}</h1> : <h2>{title}</h2>)}
          {description && <Card.Text>{description}</Card.Text>}
          {externalLink && ctaLabel && (
            <Link
              to={externalLink}
              asButton={themeColor || "primary"}
              className="d-inline-block text-uppercase"
              type="external"
              rel="noreferrer"
              title="Links opens in a new window"
              data-analytics-event78
              aria-label={ctaLabel}
            >
              {ctaLabel}
            </Link>
          )}
          {internalLink && ctaLabel && (
            <Link
              _id={title}
              asButton={themeColor || "primary"}
              to={internalLink}
              className="d-inline-block text-uppercase"
            >
              {ctaLabel}
            </Link>
          )}
        </section>
      </Card.ImgOverlay>
    </Card>
  );
};

export default VideoBanner;
