import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import {
  ImageInterface,
  LandingSectionsInterface,
  LocalizedContextInterface,
  Seo,
  VideoBannerInterface
} from "../../types/SanityTypes";
import { graphql, PageProps } from "gatsby";
import SEO from "../../components/Seo";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import StructuredData from "../../components/StructuredData";
import BlockRenderer from "../../components/BlockRenderer";
import { LocalisedPageContext } from "../../types/PageTypes";
import HomePageBanner from "../../components/HomePageBanner";
import VideoBanner from "../../components/VideoBanner";
import { slug } from "../../utils/slug-generator";

type HomepageProps = {
  home: {
    seo: Seo;
    slug: {
      current: string;
    };
    isThisHomePage: boolean;
    banner: {
      headline: any;
      subHeadline: string;
      _rawDesktopHeroImage: ImageInterface;
      _rawMobileHeroImage: ImageInterface;
      videoBanner: VideoBannerInterface;
      heroCTALabel: string;
      isExternalLink: boolean;
      externalCtaLink: string;
      display: string | null;
      ctaLink: {
        slug: {
          current: string;
        };
      };
      contentAlignment: string;
      bgColor: {
        title: string;
        value: string;
      };
    };
    name: string;
    landingSections: LandingSectionsInterface[];
  };
} & LocalizedContextInterface;

type HomePageContext = LocalisedPageContext;

export const query = graphql`
  query ($_id: String, $language: String) {
    home: sanityHomePage(_id: { eq: $_id }) {
      ...HomePageFieldsFull
    }
    ...LocalizedContext
  }
`;

const HomePage = (props: PageProps<HomepageProps, HomePageContext>) => {
  const homePage = props.data.home;
  const isVirtualStylerPage = homePage?.slug.current === "virtualstyler";
  const { htmlLang, siteName } = useSiteMetadata(props.pageContext.language);
  const thumbnail = props.data.home?.banner?._rawDesktopHeroImage?.asset?.url;

  const getPageLink = (linkObject: any) => {
    if (!linkObject) {
      return null;
    }
    if (linkObject?.__typename == "SanityProductV2") {
      if (linkObject?.parentPage) {
        return `${linkObject.parentPage.slug.current}/${slug(linkObject.productCategory.name)}/${
          linkObject.slug.current
        }`;
      } else {
        return `${slug(linkObject.productCategory.name)}/${linkObject.slug.current}`;
      }
    } else {
      if (linkObject?.parentPage) {
        return `${linkObject.parentPage.slug.current}/${linkObject.slug.current}`;
      } else {
        return `${linkObject?.slug.current}`;
      }
    }
  };

  const structuredData = { siteName };

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={homePage?.seo.metaTitle}
      pageType={homePage.isThisHomePage ? homePage.name : "Landing Page"}
      contentType={homePage?.slug.current}
      parentPages={[`${homePage?.slug.current}`]}
      hideNavigationCtaList={isVirtualStylerPage}
    >
      <StructuredData
        name={siteName}
        description={homePage?.seo.metaDescription}
        image={{
          url: ((homePage?.banner?._rawDesktopHeroImage?.asset as Record<string, unknown>)?.url as string) || ""
        }}
        slug={homePage?.slug.current}
        data={structuredData}
        language={htmlLang}
      />
      <SEO
        title={homePage?.seo.metaTitle}
        description={homePage?.seo.metaDescription}
        imageUrl={homePage?.banner?._rawDesktopHeroImage?.asset?.url}
        imageAlt={homePage?.banner?._rawDesktopHeroImage?.alt}
      />
      {homePage?.banner?.videoBanner?._rawWebm && homePage?.banner?.videoBanner?._rawFallback ? (
        <VideoBanner
          webm={homePage?.banner?.videoBanner?._rawWebm}
          fallback={homePage?.banner?.videoBanner?._rawFallback}
          aspectratio={homePage?.banner?.videoBanner?.aspectratio}
          height={540}
          alt={homePage?.banner?.videoBanner?.alt}
          caption={homePage?.banner?.videoBanner?.caption}
          title={homePage?.banner?.headline}
          description={homePage?.banner?.subHeadline}
          ctaLabel={homePage?.banner?.heroCTALabel}
          externalLink={homePage.banner?.isExternalLink ? homePage.banner?.externalCtaLink : ""}
          internalLink={getPageLink(homePage?.banner?.ctaLink)}
          isThisHomePage={homePage?.isThisHomePage}
          bgColor={homePage?.banner?.bgColor}
          contentAlignment={homePage?.banner?.contentAlignment}
          isHero={true}
        />
      ) : (
        <HomePageBanner
          title={homePage?.banner?.headline}
          description={homePage?.banner?.subHeadline}
          isThisHomePage={homePage?.isThisHomePage}
          image={homePage?.banner?._rawDesktopHeroImage}
          mobileImage={homePage?.banner?._rawMobileHeroImage}
          ctaLabel={homePage?.banner?.heroCTALabel}
          externalLink={homePage.banner?.isExternalLink ? homePage.banner?.externalCtaLink : ""}
          internalLink={getPageLink(homePage?.banner?.ctaLink)}
          contentAlignment={homePage?.banner?.contentAlignment}
          bgColor={homePage?.banner?.bgColor}
          display={homePage?.banner?.display}
          preferPerformance={true}
        />
      )}
      {homePage?.landingSections && (
        <>
          {homePage.landingSections.map((section, index) => (
            <BlockRenderer key={"landingSection" + index} section={section} index={index} />
          ))}
        </>
      )}
    </Layout>
  );
};

export default HomePage;
