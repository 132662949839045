import React, { useState, useEffect } from "react";

interface DisplayComponentProps {
  children: React.ReactNode;
  display?: string | null;
}

export const DisplayComponent: React.FC<DisplayComponentProps> = ({ children, display }) => {
  const [isDesktop, setIsDesktop] = useState<boolean>(
    () => typeof window !== "undefined" && window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== "undefined") {
        setIsDesktop(window.matchMedia("(min-width: 768px)").matches);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = !isDesktop;

  if (
    (display === "desktop" && isDesktop) ||
    (display === "mobile" && isMobile) ||
    display === "both" ||
    display == null
  ) {
    return <>{children}</>;
  }

  return null;
};
