const productExtractor = (
  slug: string,
  data: {
    _id: string;
    productLandingPage?: {
      slug: {
        current: string;
      };
    };
  },
  language: string,
  siteUrl: string,
  siteName: string,
  reviews?: {
    averageRating?: number;
    reviewCount?: number;
  }
) => {
  const productSlug = data.productLandingPage ? data.productLandingPage.slug.current : "products";
  const url = `${siteUrl}/${productSlug}/${slug}/`;

  return {
    brand: {
      "@type": "Brand",
      name: siteName
    },
    url: url,
    gtin8: data._id,
    aggregateRating: {
      "@type": "aggregateRating",
      ratingValue: reviews?.averageRating || 0,
      reviewCount: reviews?.reviewCount || 0
    }
  };
};

export default productExtractor;
